import HttpService from '../../utils/httpService';
import { Get, Post } from '../../services/api.service'

export function gameZopFetchData() {
    return () => {
        try {
            return new Promise((resolve, reject) => {
                // Get(HttpService.gameZop).then((result) => {
                //     resolve(result);
                // }, (error) => {
                //     reject(error);
                // });
                reject()
            });
        } catch (error) {

        }
    }
}

export function profileFetchData() {
    return () => {
        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.ProfileResult, { id: localStorage.getItem('userID') }).then((result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}

export function profileUpdateData(data) {
    const dataObj = {
        id: localStorage.getItem('userID'),
        name: data?.userName,
        mobile: data?.userMobileNumber,
        gender: data?.userGender,
        dob: data?.userDob,
        profile: null,
        email: data?.userEmail,
        address: data?.userAddress,
        anniversary_date: data?.anniversarydate,
        family_members: data?.FamilyMemberCount,
        father_mobile: data?.FatherNumber,
        father_name: data?.FatherName,
        relationship_status: data?.Relationshipstatus,
        residence_type: data?.Residencetype,
        spouse_mobile: data?.spousemobile,
        spouse_name: data?.spousename,
        token: data?.token,
        u_date: Date('Y-m-d H:i:s'),
    };
    return () => {
        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.profileUpdateResult, dataObj).then((result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}

export function sharecount(data, id) {
    const dataObj = {
        userid: localStorage.getItem('userID'),
        name: data,
        postid: id,
        agent: navigator.userAgent,
        time: new Date().toString(),
        screen: window.screen.width + 'x' + window.screen.height,
        screenuse: window.innerWidth + 'x' + window.innerHeight,
    };

    return () => {
        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.analytics, dataObj).then((result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}

export function open_app(id,category_url) {
    const dataObj = {
        userid: localStorage.getItem('userID'),
        postid: id,
        category_url: category_url,
        screen: window.screen.width + 'x' + window.screen.height,
        screenuse: window.innerWidth + 'x' + window.innerHeight,
    };

    return () => {
        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.openapp, dataObj).then((result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}


export function googleLogin(data) {
    const dataObj = {
        email: data?.profileObj?.email,
        familyName: data?.profileObj?.familyName,
        givenName: data?.profileObj?.givenName,
        uid: data?.profileObj?.googleId,
        imageUrl: data?.profileObj?.imageUrl,
        name: data?.profileObj?.name,
        type: data?.tokenObj?.idpId
    }
    return () => {

        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.googleResult, dataObj).then((result) => {
                    resolve(result);
                    if (result?.user_data) {
                        localStorage.setItem('access_token', result?.user_data?.token);
                        localStorage.setItem('userInfo', JSON.stringify(result));
                        localStorage.setItem('userID', JSON.stringify(result?.user_data?.id));
                    }
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}


export function facebookLogin(data) {
    const dataObj = {
        email: data?.email,
        familyName: data?.name,
        givenName: data?.name,
        uid: data?.id,
        imageUrl: data?.picture?.data?.url,
        name: data?.name,
        type: data?.graphDomain
    }
    return () => {
        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.googleResult, dataObj).then((result) => {
                    resolve(result);
                    if (result?.user_data) {
                        localStorage.setItem('access_token', result?.user_data?.token);
                        localStorage.setItem('userInfo', JSON.stringify(result));
                        localStorage.setItem('userID', JSON.stringify(result?.user_data?.id));
                    }
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}

export function postComment(data) {
    return () => {
        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.postComment, data).then((result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}
export function reportComment(data) {
    return () => {
        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.reportcomment, data).then((result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}

export function forgetPassword(userDetail) {
    return () => {
        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.forgetPassword, userDetail).then((result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}
export function updatePassword(userDetail) {
    return () => {
        try {
            return new Promise((resolve, reject) => {
                Post(HttpService.updatepassword, userDetail).then((result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {

        }
    }
}